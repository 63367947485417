import { Route } from "react-router-dom";
import Content from "./pages/content";
import Report from "./pages/report";
import WhyLogin from "./pages/whylogin";

export default function App() {
  return (
    <>
      <Route path="/" component={Content} exact />
      <Route path="/report" component={Report} exact />
      <Route path="/whylogin" component={WhyLogin} />
    </>
  );
}
