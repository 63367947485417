import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const windowAny: any = window;
toast.configure();

export default function useLogin() {
  const redirectURL = "https://v2.dailyscrap.news";
  // const redirectURL = "http://localhost:3000";

  const [userInfoData, setUserInfoData] = useState<any>({
    id: null,
    nickname: null,
    thumbnail_image: null,
    profile_image: null,
  });

  function handleClickLogin() {
    windowAny.Kakao.Auth.authorize({
      redirectUri: redirectURL,
    });
  }

  function handleClickLogout() {
    if (!windowAny.Kakao.Auth.getAccessToken()) {
      console.log("Not logged in.");
      return;
    }
    windowAny.Kakao.Auth.logout(function () {
      console.log("logout", windowAny.Kakao.Auth.getAccessToken());
      setUserInfoData({
        id: null,
        nickname: null,
        thumbnail_image: null,
        profile_image: null,
      });

      localStorage.removeItem("CC_Token");
      localStorage.removeItem("RF_Token");
      localStorage.removeItem("id");
      localStorage.removeItem("nickname");
      localStorage.removeItem("thumbnail_image");
      localStorage.removeItem("profile_image");
    });
  }

  useEffect(() => {
    if (localStorage.length !== 0) {
      const userInfoData: any = {
        id: localStorage.getItem("id"),
        nickname: localStorage.getItem("nickname"),
        thumbnail_image: localStorage.getItem("thumbnail_image"),
        profile_image: localStorage.getItem("profile_image"),
      };
      setUserInfoData(userInfoData);
    }

    let code = new URL(windowAny.location.href).searchParams.get("code");
    axios
      .post(
        "https://kauth.kakao.com/oauth/token?grant_type=authorization_code&client_id=a7a67508a10d27a3c9d82683b038274b&redirect_uri=" +
          redirectURL +
          "&code=" +
          code
      )
      .then((res) => {
        let access_token = res.data.access_token;
        let refresh_token = res.data.refresh_token;
        localStorage.setItem("CC_Token", access_token);
        localStorage.setItem("RF_Token", refresh_token);

        windowAny.Kakao.Auth.setAccessToken(access_token);

        windowAny.Kakao.API.request({
          url: "/v2/user/me",
          success: function (response) {
            // console.log("유저 정보 조회 response:", response);
            const userInfoData = {
              id: response.id,
              nickname: response.kakao_account.profile.nickname,
              thumbnail_image:
                response.kakao_account.profile.thumbnail_image_url,
              profile_image: response.kakao_account.profile.profile_image_url,
            };
            setUserInfoData(userInfoData);

            axios({
              method: "put",
              url: "https://4mgu13g90h.execute-api.ap-northeast-2.amazonaws.com/v1/api/createuserdata",
              data: {
                kakao_id: userInfoData.id,
                kakao_nickname: userInfoData.nickname,
                kakao_profile_image: userInfoData.profile_image,
              },
            }).then((res) => {
              let testCreateUserData = res.data.body;
              // console.log("api/createuserdata : ", testCreateUserData);
            });

            localStorage.setItem("id", userInfoData.id);
            localStorage.setItem("nickname", userInfoData.nickname);
            localStorage.setItem(
              "thumbnail_image",
              userInfoData.thumbnail_image
            );
            localStorage.setItem("profile_image", userInfoData.profile_image);

            let toastMsg = userInfoData.nickname + "님 환영합니다.";
            const notify = () =>
              toast.success(toastMsg, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            notify();
          },
          fail: function (error) {
            console.log("유저 정보 조회 error:", error);
          },
        });
      });
  }, []);

  return { handleClickLogin, handleClickLogout, userInfoData, setUserInfoData };
}
