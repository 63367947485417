export default function Actions() {
  return (
    <>
      <div className="flex justify-between pt-3 pl-3 pr-3 pb-1 md:border-t border-gray-primary">
        <div className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            tabIndex={0}
            className="w-8 select-none cursor-pointer focus:outline-none"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              strokeWidth={1}
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg>
          {/* <p className="font-bold text-sm">좋아요 10</p> */}
          <div className="w-2"></div>
          <svg
            className="w-7 select-none cursor-pointer focus:outline-none"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            tabIndex={0}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.1}
              d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
            />
          </svg>
          {/* <p className="font-bold text-sm">북마크 2</p> */}
        </div>
      </div>
      {/* <div className="p-4 py-0">
        <p className="font-bold text-xs">좋아요 10</p>
      </div> */}
    </>
  );
}
