import { useEffect, useState, useRef, useCallback } from "react";
import Post from "./post";
import useContent from "../hooks/use-content";
import Lottie from "react-lottie";
import animationData from "../images/890-loading-animation.json";

export default function ContentFeed({ dataRange, dataRangeSetup }) {
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [content, isLoading, isError]: any = useContent(
    dataRange,
    isEnd,
    dataRangeSetup
  );
  const [wholeContent, setWholeContent] = useState<any>([]);
  const [isStopped, setIsStopped] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [isInfiniteEnd, setIsInfiniteEnd] = useState(false);

  const observer = useRef<any>();

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setTimeout(function () {
            setIsEnd(true);
            // DataRange 바운더리 설정
            const infiniteEndData = new Date("2021-06-01");
            const elapsedDate =
              new Date().getDate() - infiniteEndData.getDate() + 1;
            if (dataRange.end === elapsedDate) {
              setIsInfiniteEnd(true);
            } else {
              dataRangeSetup({
                start: (dataRange.start += 1),
                end: (dataRange.end += 1),
              });
            }
            setIsEnd(false);
          }, 400);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading]
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const newArr: String[] = [];
    content.forEach((element) => {
      let ExistenceStatus = wholeContent.findIndex(
        (i) => i.url === element.url
      );

      if (ExistenceStatus === -1) {
        // 중복되지 않는 경우
        newArr.push(element);
        setWholeContent(wholeContent.concat(newArr));
      } else {
        // 중복되는 경우
      }
    });
  }, [content]);

  return (
    <div>
      {wholeContent.length > 0 &&
        wholeContent.map((contentElement, index) => (
          <>
            <Post key={index} content={contentElement} />
            <div
              id={index}
              ref={index + 1 === wholeContent.length ? lastElementRef : null}
            ></div>
          </>
        ))}
      {isEnd === false && isInfiniteEnd === false && (
        <div className="-my-10">
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
            isStopped={isStopped}
            isPaused={isPaused}
          />
        </div>
      )}
      {isInfiniteEnd === true && (
        <div className="text-center text-sm my-20 text-gray-400">
          <p>여기가 마지막이에요.</p>
        </div>
      )}
    </div>
  );
}
