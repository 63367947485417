export default function Image({ content }) {
  return (
    <>
      {content.thumbnailURL && (
        <img
          style={{
            width: "100%",
          }}
          alt="thumbnail"
          src={content.thumbnailURL}
        />
      )}
      {content.thumbnails && (
        <img
          style={{
            width: "100%",
          }}
          alt="thumbnail"
          src={content.thumbnails.url}
        />
      )}
    </>
  );
}
