import AWS from "aws-sdk";
import formatDate from "../utils/format-date";

AWS.config.region = "ap-northeast-2";
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "ap-northeast-2:3118f9c7-9559-462f-b791-117fb9135afe",
});

const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: "chartandnews" },
});

export async function getContentS3(dayStart, dayEnd) {
  const aggContentsArr: any = [];

  for (let i = dayStart; i < dayEnd; i++) {
    const targetDate = formatDate(
      new Date(new Date().setDate(new Date().getDate() - i))
    );
    const S3ParamsArticle: any = {
      Bucket: "chartandnews",
      Key: "content/article/remembernow/remembernow_" + targetDate + ".json",
    };

    // 해당 파일이 있는지 먼저 체크
    try {
      const headCode = await s3.headObject(S3ParamsArticle).promise();
      const S3DataArticle: any = (await s3.getObject(S3ParamsArticle).promise())
        .Body;
      const S3ResultArticle = JSON.parse(S3DataArticle);
      if (S3ResultArticle[targetDate]) {
        for (let i = 0; i < S3ResultArticle[targetDate].length; i++) {
          S3ResultArticle[targetDate][i]["targetDate"] = targetDate;
          aggContentsArr.push(S3ResultArticle[targetDate][i]);
        }
      }
    } catch (headErr) {
      aggContentsArr.push("");
    }
  }

  for (let i = dayStart; i < dayEnd; i++) {
    const targetDate = formatDate(
      new Date(new Date().setDate(new Date().getDate() - i))
    );
    const S3ParamsEconomyhand: any = {
      Bucket: "chartandnews",
      Key: "content/audio/economyhand_" + targetDate + ".json",
    };

    // 해당 파일이 있는지 먼저 체크
    try {
      const headCode = await s3.headObject(S3ParamsEconomyhand).promise();
      const S3DataEconomyhand: any = (
        await s3.getObject(S3ParamsEconomyhand).promise()
      ).Body;
      const S3ResultEconomyhand = JSON.parse(S3DataEconomyhand);
      if (S3ResultEconomyhand[targetDate]) {
        for (let i = 0; i < S3ResultEconomyhand[targetDate].length; i++) {
          S3ResultEconomyhand[targetDate][i]["targetDate"] = targetDate;
          aggContentsArr.push(S3ResultEconomyhand[targetDate][i]);
        }
      }
    } catch (headErr) {
      aggContentsArr.push("");
    }
  }

  for (let i = dayStart; i < dayEnd; i++) {
    const targetDate = formatDate(
      new Date(new Date().setDate(new Date().getDate() - i))
    );
    const S3ParamsYouTube: any = {
      Bucket: "chartandnews",
      Key: "content/youtube/youtube_" + targetDate + ".json",
    };

    // 해당 파일이 있는지 먼저 체크
    try {
      const headCode = await s3.headObject(S3ParamsYouTube).promise();
      const S3DataYouTube: any = (await s3.getObject(S3ParamsYouTube).promise())
        .Body;
      const S3ResultYouTube = JSON.parse(S3DataYouTube);
      if (S3ResultYouTube[targetDate]) {
        for (let i = 0; i < S3ResultYouTube[targetDate].length; i++) {
          S3ResultYouTube[targetDate][i]["targetDate"] = targetDate;
          aggContentsArr.push(S3ResultYouTube[targetDate][i]);
        }
      }
    } catch (headErr) {
      aggContentsArr.push("");
    }
  }

  for (let i = dayStart; i < dayEnd; i++) {
    const targetDate = formatDate(
      new Date(new Date().setDate(new Date().getDate() - i))
    );
    const S3ParamsLetter: any = {
      Bucket: "chartandnews",
      Key: "content/newsletter/uppity/uppity_" + targetDate + ".json",
    };

    // 해당 파일이 있는지 먼저 체크
    try {
      const headCode = await s3.headObject(S3ParamsLetter).promise();
      const S3DataLetter: any = (await s3.getObject(S3ParamsLetter).promise())
        .Body;
      const S3ResultLetter = JSON.parse(S3DataLetter);
      if (S3ResultLetter[targetDate]) {
        for (let i = 0; i < S3ResultLetter[targetDate].length; i++) {
          S3ResultLetter[targetDate][i]["targetDate"] = targetDate;
          aggContentsArr.push(S3ResultLetter[targetDate][i]);
        }
      }
    } catch (headErr) {
      aggContentsArr.push("");
    }
  }

  return aggContentsArr;
}

export async function getSearched(dayStart, dayEnd, query) {
  for (let i = dayStart; i < dayEnd; i++) {
    const targetDate = formatDate(
      new Date(new Date().setDate(new Date().getDate() - i))
    );

    const S3ParamsArticle: any = {
      Bucket: "chartandnews",
      Key: "content/article/remembernow/remembernow_" + targetDate + ".json",
      ExpressionType: "SQL",
      Expression:
        "SELECT * FROM s3object[*]['" +
        targetDate +
        "'][*] t WHERE t.title LIKE '%" +
        query +
        "%'",
      InputSerialization: {
        JSON: {
          Type: "DOCUMENT",
        },
      },
      OutputSerialization: {
        JSON: {
          RecordDelimiter: "++",
        },
      },
    };

    s3.selectObjectContent(S3ParamsArticle, (err, data: any) => {
      if (data.Payload.length > 2) {
        try {
          const Uint8ArrayConvert = new TextDecoder().decode(
            data.Payload[0].Records.Payload
          );
          const Uint8ArraySerialize = JSON.parse(
            JSON.stringify(Uint8ArrayConvert)
          );
          const elementArr: any = [];
          let resultSplit = Uint8ArraySerialize.split("++");
          for (var i = 0; i < resultSplit.length - 1; i++) {
            let split1 = resultSplit[i].split('{"source":');
            let split2 = split1[1].split(',"summary":');
            let split3 = split2[1].split(',"thumbnailURL":');
            let split4 = split3[1].split(',"title":');
            let split5 = split4[1].split(',"url":');

            const source = split2[0].trim().replaceAll('"', "");
            const summary = split3[0].trim().replaceAll('"', "");
            const thumbnailURL = split4[0].trim().replaceAll('"', "");
            const title1 = split5[0].trim().replaceAll('"', "");
            const title = title1.trim().replaceAll("\\", "");
            const url1 = split5[1].trim().replaceAll('"', "");
            const url = url1.trim().replaceAll("}", "");

            const element = {
              source: source,
              summary: summary,
              thumbnailURL: thumbnailURL,
              title: title,
              url: url,
            };

            elementArr.push(element);
          }
          return elementArr;
        } catch (err) {
          throw new Error(`Could not retrieve file from S3: ${err.message}`);
        }
      }
    });
  }
}
