import { useState, useEffect } from "react";
import { getContentS3 } from "../services/s3Content";
import formatDate from "../utils/format-date";

export default function useContent(dataRange, isEnd, dataRangeSetup) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [content, setContent] = useState([]);

  const targetDate = formatDate(
    new Date(new Date().setDate(new Date().getDate() - dataRange.start))
  );

  let i = 1;

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    getContentS3(dataRange.start, dataRange.end).then((result) => {
      const contentOnly: any = [];
      if (result && result.length > 0) {
        result.map((element, index) => {
          element["date"] = targetDate;
          contentOnly.push(element);
        });
        // re-arrange by date
        contentOnly.sort(function (a, b) {
          return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
        });
        setContent(contentOnly);
        setIsLoading(false);
      } else if (result.length === 0) {
        do {
          dataRangeSetup({
            start: (dataRange.start += i),
            end: (dataRange.end += i),
          });
          i++;
          console.log("dataRange", dataRange);
        } while (result.length > 0);
      } else {
        setIsError(true);
        setIsLoading(false);
        console.log("getContentsS3 Error!");
      }
    });
  }, [isEnd, dataRange]);

  return [content, isError, isLoading];
}
