import AWS from "aws-sdk";
import formatDate from "../utils/format-date";

AWS.config.region = "ap-northeast-2";
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "ap-northeast-2:3118f9c7-9559-462f-b791-117fb9135afe",
});

const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: "chartandnews" },
});

export async function getReportS3(dayStart, dayEnd) {
  const aggReportArr: any = [];

  for (let i = dayStart; i < dayEnd; i++) {
    const targetDate = formatDate(
      new Date(new Date().setDate(new Date().getDate() - i))
    );
    const S3ParamsReport: any = {
      Bucket: "chartandnews",
      Key:
        "content/report/hankyungconsensus/hankyungconsensus_" +
        targetDate +
        ".json",
    };

    // 해당 파일이 있는지 먼저 체크
    try {
      const headCode = await s3.headObject(S3ParamsReport).promise();
      const S3DataReport: any = (await s3.getObject(S3ParamsReport).promise())
        .Body;
      const S3ResultReport = JSON.parse(S3DataReport);
      if (S3ResultReport[targetDate]) {
        for (let i = 0; i < S3ResultReport[targetDate].length; i++) {
          S3ResultReport[targetDate][i]["targetDate"] = targetDate;
          aggReportArr.push(S3ResultReport[targetDate][i]);
        }
      }
    } catch (headErr) {
      aggReportArr.push("");
    }
  }

  return aggReportArr;
}
