import { useState } from "react";
import Header from "../components/header/index";
import ReportFeed from "../components/reportfeed";
import FadeIn from "react-fade-in";

export default function Report() {
  const [dataRange, setDataRange] = useState({ start: 1, end: 2 });

  function dataRangeSetup(value) {
    setDataRange(value);
  }

  return (
    <div className="bg-gray-50">
      <Header />
      <FadeIn>
        <div className="mx-auto max-w-screen-sm">
          <ReportFeed dataRange={dataRange} dataRangeSetup={dataRangeSetup} />
        </div>
      </FadeIn>
    </div>
  );
}
