import { useState } from "react";
import Header from "../components/header/index";
import ContentTicker from "../components/contentticker";
import ContentFeed from "../components/contentfeed";
import FadeIn from "react-fade-in";

export default function Content() {
  const [dataRange, setDataRange] = useState({ start: 0, end: 1 });

  function dataRangeSetup(value) {
    setDataRange(value);
  }

  return (
    <div className="bg-gray-50">
      <Header />
      <FadeIn>
        {/* <div className="mx-auto max-w-screen-sm"><ContentTicker /></div> */}
        <div className="mx-auto max-w-screen-sm mb-12">
          <ContentFeed dataRange={dataRange} dataRangeSetup={dataRangeSetup} />
        </div>
      </FadeIn>
    </div>
  );
}
