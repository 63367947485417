export default function Footer({ content }) {
  let convertDate = new Date(content.targetDate),
    month = "" + (convertDate.getMonth() + 1),
    day = "" + convertDate.getDate(),
    year = convertDate.getFullYear();
  const displayDate = year + "년 " + month + "월 " + day + "일";

  return (
    <div className="pb-4 pl-4 pr-4">
      {content.title && (
        <p className="font-bold text-sm leading-5 mt-2 mb-4">{content.title}</p>
      )}
      {content.news_title && (
        <>
          <p className="font-bold text-sm leading-5 mt-2 mb-4">
            {content.news_title}
          </p>
          <p className="font-bold text-sm leading-5 mt-2 mb-4">
            {content.serial_title}
          </p>
        </>
      )}
      {content.summary !== null && content.pdfURL !== null && (
        <p className="text-sm leading-5">{content.summary}</p>
      )}
      <p className="text-tiny text-gray-base mt-4">{displayDate}</p>
    </div>
  );
}
