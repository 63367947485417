import { useEffect, useState, useRef, useCallback } from "react";
import useReport from "../hooks/use-report";
import Lottie from "react-lottie";
import animationData from "../images/890-loading-animation.json";

export default function ReportFeed({ dataRange, dataRangeSetup }) {
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [report, isLoading, isError]: any = useReport(
    dataRange,
    isEnd,
    dataRangeSetup
  );
  const [wholeReport, setWholeReport] = useState<any>([]);
  const [isStopped, setIsStopped] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [isInfiniteEnd, setIsInfiniteEnd] = useState(false);

  const observer = useRef<any>();

  let i = 1;
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setTimeout(function () {
            setIsEnd(true);
            // DataRange 바운더리 설정
            const infiniteEndData = new Date("2021-06-01");
            const elapsedDate =
              new Date().getDate() - infiniteEndData.getDate() + 1;
            if (dataRange.end === elapsedDate) {
              setIsInfiniteEnd(true);
            } else {
              do {
                dataRangeSetup({
                  start: (dataRange.start += i),
                  end: (dataRange.end += i),
                });
                i++;
              } while (report.length > 0);
            }
            setIsEnd(false);
          }, 400);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading]
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const newArr: String[] = [];
    report.forEach((element) => {
      let ExistenceStatus = wholeReport.findIndex(
        (i) => i.pdfURL === element.pdfURL
      );

      if (ExistenceStatus === -1) {
        // 중복되지 않는 경우
        newArr.push(element);
        setWholeReport(wholeReport.concat(newArr));
      } else {
        // 중복되는 경우
      }
    });
  }, [report]);

  return (
    <>
      <div className="rounded md:border md:bg-white border-gray-300 mb-12">
        <div className="flex items-center justify-center">
          <div className="container">
            <div className="flex justify-center">
              <div className="w-full">
                <ul className="divide-y divide-gray-100">
                  {wholeReport.length > 0 &&
                    wholeReport.map((contentElement, index) => (
                      <>
                        <li className="p-4 hover:bg-white md:hover:bg-gray-100 cursor-pointer leading-7">
                          <div className="flex">
                            <p className="truncate text-lg">
                              {contentElement.title}
                            </p>
                          </div>
                          <div className="flex">
                            <p className="truncate text-sm">
                              {contentElement.house}
                            </p>
                            <p>&nbsp;•&nbsp;</p>
                            <p className="truncate text-sm">
                              {contentElement.writer}
                            </p>
                            <p>&nbsp;•&nbsp;</p>
                            <p className="truncate text-sm">
                              {contentElement.date}
                            </p>
                          </div>
                        </li>
                        <div
                          id={index}
                          ref={
                            index + 1 === wholeReport.length
                              ? lastElementRef
                              : null
                          }
                        ></div>
                      </>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isEnd === false && isInfiniteEnd === false && (
        <div className="-my-10">
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
            isStopped={isStopped}
            isPaused={isPaused}
          />
        </div>
      )}
      {isInfiniteEnd === true && (
        <div className="text-center text-sm my-20 text-gray-400">
          <p>여기가 마지막이에요.</p>
        </div>
      )}
    </>
  );
}
