import dailyscrap_v2_logo from "../../images/dailyscrap_v2.png";
import LoginButton from "./loginbutton";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <>
      <header className="fixed z-50 overflow-hidden top-0 h-16 w-full bg-white border-b border-gray-300 mb-8">
        <div className="container mx-auto max-w-screen-lg h-full">
          <div className="flex justify-between h-full">
            <div className="text-center flex items-center align-items cursor-pointer">
              <h1 className="flex ml-4 mr-6 w-24 h-12">
                <img
                  src={dailyscrap_v2_logo}
                  alt="Daily Scrap"
                  className="mt-2"
                />
              </h1>
            </div>
            <LoginButton />
          </div>
        </div>
      </header>
      <div className="mt-20 mb-5 text-center">
        <div className="inline-flex">
          <Link to="/">
            <button className="hover:bg-gray-200 text-gray-800 text-sm font-bold py-2 px-4 rounded">
              Content
            </button>
          </Link>
          <Link to="/report">
            <button className="hover:bg-gray-200 text-gray-800 text-sm font-bold py-2 px-4 rounded">
              Report
            </button>
          </Link>
          {/* <button className="hover:bg-gray-200 text-gray-800 text-sm font-bold py-2 px-4 rounded">
            News
          </button> */}
        </div>
      </div>
    </>
  );
}
