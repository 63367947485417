import { Link } from "react-router-dom";
import useLogin from "../../hooks/use-login";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

toast.configure();
export default function LoginButton() {
  const { userInfoData, handleClickLogout } = useLogin();
  const [open, setOpen] = useState(false);

  const cancelButtonRef = useRef(null);

  let toastMsg = "로그아웃 되었습니다.";
  const notify = () =>
    toast.success(toastMsg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1500,
    });

  function logoutAndClose() {
    handleClickLogout();
    setOpen(false);
    notify();
  }

  return (
    <>
      <div className="text-center flex items-center align-items">
        {userInfoData.id == null ? (
          <Link to="/whylogin">
            <svg
              className="w-7 mr-4 select-none cursor-pointer focus:outline-none"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
              />
            </svg>
          </Link>
        ) : (
          <img
            className="rounded-full border mr-4 h-9 w-9 flex select-none cursor-pointer focus:outline-none"
            src={userInfoData.thumbnail_image}
            alt={userInfoData.nickname}
            onClick={() => setOpen(true)}
          />
        )}
      </div>

      {/* Profile Card Modal */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center md:block md:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden md:inline-block md:align-middle md:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <div className="inline-block align-middle bg-white rounded-lg text-center overflow-hidden shadow transform transition-all my-8 md:align-middle max-w-sm w-full md:w-6/12">
                {/* Profile Card Start */}
                <div className="flex justify-center mt-5">
                  <img
                    src={userInfoData.thumbnail_image}
                    className="z-10 rounded-full w-24 h-24 border-solid border-gray border-2"
                  />
                </div>
                <div className="text-center px-3 py-3 pt-2">
                  <h3 className="text-black text-lg text-bold">
                    {userInfoData.nickname}
                  </h3>
                </div>
                <div className="flex flex-row  justify-center">
                  <div className="justify-center pb-5 text-grey-dark">
                    <button
                      onClick={() => console.log("좋아요 이력으로 이동")}
                      className="select-none cursor-pointer focus:outline-none"
                    >
                      <div className="text-center mr-3 border-r pr-3">
                        <h2 className="text-sm">34 좋아요</h2>
                      </div>
                    </button>
                    <button
                      onClick={() => console.log("북마크 콘텐츠로 이동")}
                      className="select-none cursor-pointer focus:outline-none"
                    >
                      <div className="text-center">
                        <h2 className="text-sm">42 북마크</h2>
                      </div>
                    </button>
                    <div className="pt-4 flex gap-3">
                      <button
                        type="button"
                        className="mt-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-kakaoyellow text-base font-medium text-black hover:bg-opacity-60 focus:outline-none w-auto text-sm select-none cursor-pointer"
                        onClick={logoutAndClose}
                      >
                        로그아웃
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 focus:outline-none w-auto text-sm select-none cursor-pointer"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        닫기
                      </button>
                    </div>
                  </div>
                  {/* Profile Card End */}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
