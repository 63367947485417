import { useState, useEffect } from "react";
import { getReportS3 } from "../services/s3Report";
import formatDate from "../utils/format-date";

export default function useReport(dataRange, isEnd, dataRangeSetup) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [report, setReport] = useState([]);

  const targetDate = formatDate(
    new Date(new Date().setDate(new Date().getDate() - dataRange.start))
  );

  let i = 1;

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    getReportS3(dataRange.start, dataRange.end).then((result) => {
      const reportOnly: any = [];
      if (result && result.length > 0) {
        result.map((element, index) => {
          element["date"] = targetDate;
          reportOnly.push(element);
        });
        // re-arrange by date
        reportOnly.sort(function (a, b) {
          return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
        });
        setReport(reportOnly);
        setIsLoading(false);
      } else if (result.length === 0) {
        do {
          dataRangeSetup({
            start: (dataRange.start += i),
            end: (dataRange.end += i),
          });
          i++;
          console.log("dataRange", dataRange);
        } while (result.length > 0);
      } else {
        setIsError(true);
        setIsLoading(false);
        console.log("getContentsS3 Error!");
      }
    });
  }, [isEnd, dataRange]);

  return [report, isError, isLoading];
}
