/* eslint-disable jsx-a11y/img-redundant-alt */
import remembernow_logo from "../../images/remembernow_logo.png";
import youtube_logo from "../../images/youtube_logo.png";
import podcast_logo from "../../images/podcast_logo.png";
import newsletter_logo from "../../images/newsletter_logo.png";

export default function Header({ content }) {
  return (
    <div className="flex md:border-b border-gray-primary h-4 p-4 py-8">
      <div className="flex items-center">
        {content.source == "리멤버나우" && (
          <img
            className="rounded-full  h-10 w-10 flex mr-3"
            src={remembernow_logo}
            alt="리멤버나우 아이콘"
          />
        )}
        {content.channelTitle !== null &&
          content.channelTitle !== undefined && (
            <img
              className="rounded-full  h-10 w-10 flex mr-3"
              src={youtube_logo}
              alt="유튜브 아이콘"
            />
          )}
        {content.news_title == null &&
          content.source == null &&
          content.channelTitle == null &&
          content.house == null && (
            <img
              className="rounded-full  h-10 w-10 flex mr-3"
              src={podcast_logo}
              alt="이진우의 손에 잡히는 경제 아이콘"
            />
          )}
        {content.news_title !== null && content.news_title !== undefined && (
          <img
            className="rounded-full  h-10 w-10 flex mr-3"
            src={newsletter_logo}
            alt="뉴스레터 아이콘"
          />
        )}
        {content.news_title !== null && content.news_title !== undefined && (
          <p className="font-bold text-sm">UPPITY</p>
        )}
        {content.source !== null && (
          <p className="font-bold text-sm">{content.source}</p>
        )}
        {content.channelTitle !== null && (
          <p className="font-bold text-sm">{content.channelTitle}</p>
        )}
        {content.news_title == null &&
          content.source == null &&
          content.channelTitle == null &&
          content.house == null && (
            <p className="font-bold text-sm">이진우의 손에 잡히는 경제</p>
          )}
      </div>
    </div>
  );
}
