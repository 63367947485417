import Header from "./header";
import Image from "./image";
import Actions from "./actions";
import Footer from "./footer";
import Comments from "./comments";
import FadeIn from "react-fade-in";

export default function Post({ content }) {
  return (
    <div className="rounded md:border md:bg-white border-gray-300 mb-12">
      <FadeIn>
        <Header content={content} />
        <Image content={content} />
        <Actions />
        <Footer content={content} />
        {/* <Comments content={content} /> */}
      </FadeIn>
    </div>
  );
}
