import { Link } from "react-router-dom";
import useLogin from "../hooks/use-login";

export default function WhyLogin() {
  const { handleClickLogin } = useLogin();

  return (
    <div className="w-full p-8 gap-10 flex flex-col">
      <div className="w-full flex justify-between">
        <p className="font-bold text-3xl">로그인</p>
        <Link to="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8 select-none cursor-pointer focus:outline-none"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </Link>
      </div>
      <div className="w-full gap-5 flex-row justify-center">
        <p className="font-bold pt-10 text-2xl">경제 콘텐츠 모음</p>
        <p className="font-bold text-4xl">Daily Scrap</p>
        <p className="pt-10 text-lg leading-6">
          데일리 스크랩은 경제 분야 콘텐츠와 뉴스를 선별하여 매일 스크랩합니다.
        </p>
        <p className="pb-10 text-lg leading-6">
          로그인 후 더 많은 콘텐츠를 확인해보세요.
        </p>
        <button
          type="button"
          className="mt-10 inline-flex justify-center rounded-md border border-transparent shadow-sm px-6 py-4 bg-kakaoyellow text-base font-bold text-black hover:bg-opacity-60 focus:outline-none w-full sm:w-auto text-lg select-none cursor-pointer"
          onClick={handleClickLogin}
        >
          카카오톡으로 로그인하기
        </button>
      </div>
    </div>
  );
}
